import React from 'react'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Welcome from '../../components/parent/welcome'
import Links from '../../components/parent/links'
import Articles from '../../components/parent/articles'
import Curiosity from '../../components/parent/curiosity'
import ParentsTools from '../../components/parent/parents-tools'
import Experts from '../../components/parent/experts'
import Workshops from '../../components/parent/workshops'
import Podcasts from '../../components/parent/podcasts'
import ParentArea from '../../components/parent/parent-area'
import TopScroll from '../../components/parent/top-scroll'

const IndexPageWrapper = styled.div`
  /* background-color: #f8f8f8; */
  background: rgba(184, 225, 210, 1);
  background: linear-gradient(
    -50deg,
    rgba(184, 225, 210, 1) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  @media (max-width: 767px) {
    overflow: hidden;
  }

  @media (max-width: 767px) {
    overflow: hidden;
  }
  @media (max-width: 575px) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      70deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(184, 225, 210, 1) 60%
    );
  }
`

const IndexPageInnerWrapper = styled.div`
  background: rgb(244, 242, 247);
  background: linear-gradient(
    50deg,
    rgba(244, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0.35) 60%
  );
  @media (max-width: 575px) {
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 20%
    );
  }
`

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO title="Strefa rodzica" />
      <IndexPageWrapper>
        <IndexPageInnerWrapper>
          <Layout location={location}>
            <Welcome />
            {false && <Links />}
            {false && <Experts />}
            {false && <Workshops />}
            {false && <Articles />}
            {false && <Podcasts />}
            {false && <Curiosity />}
            {false && <ParentsTools />}
            {false && <ParentArea page="parent" />}
            {false && <TopScroll />}
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
